<template>
  <div class="text-right">
    <van-list v-if="userInfo!=''" v-model="loading" :finished="finished" :loading-text="$t('transfer.Loading')" :error.sync="error" :error-text="$t('transfer.error')" @load="getAddressBook">
      <div class="flexs" v-for="(item, index) in userInfo" :key="index" style="margin-top: 0.51rem;" @click='chuandi(item.userId)'>
        <div class="flexs" style="flex-grow: 1;">
          <van-image class="avatar" :src="item.headIcon" error-icon='https://image2.pocketliveapp.com/image/icon/static/def_icon.png' />
          <div class="userInfo">
            <div class="name">{{item.nickname}}</div>
            <div class="userid">ID：{{item.userId}}</div>
            <div class="flexs "><img class="guoqi" :src="item.areaUrl" alt=""><span class="diqus">{{item.diqu}}</span></div>
          </div>
        </div>
        <img class="jiantou" src="../../assets/transfer/youbian.png" alt="">
      </div>
    </van-list>
    <div v-else class="norecord" style="top:30%">
      <div style="text-align:center">
        <img style="width:2.56rem;height:2.56rem" src="../../assets/transfer/record.png" alt="">
        <div class="noTransfer">{{$t('transfer.contact_content30')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import language from '../../providers/CountryCode.json'
import Cookies from 'js-cookie';


export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userid: '',
      enterIDshow: true,
      bottom: 0,
      userInfo: [],
      userInfos: null,
      token: null,
      uid: null,
      headers: null,
      // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOVFUwIiwieHl6IjoiUTBGRk5VTXhPRFV0UlRJMk1pMDBNREJGTFVFNFFVUXROa0U0T1VSRk9FRXhNVGMyIiwiZXhwIjoxOTEzNzk5NTUwfQ.xvwLcdHT2D5e5KH6siiVqQBRxfN-7Mz6H3KhjLQvVsQ',
      // uid: 554,
      // headers: [
      //   'Content-Type:' + 'application/json; charset=utf-8',
      //   'deviceId:' + 'CAE5C185-E262-400E-A8AD-6A89DE8A1176',
      //   'lang:' + 'zh_CN',
      //   'version:' + '2.1.39',
      //   'os:' + '2',
      //   'model:' + 'model:iPhone_XR_14.8.1',
      //   'fid:' + '0',
      // ],
      page: 0,
      size: 10,
      loading: false,
      finished: false,
      error: false,
    };
  },
  created() {
    // this.$completes.loadErudaJs()
    // document.getElementsByTagName("html")[0].style.padding = "0";
    //调取原生方法
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
  },
  mounted() {
    // this.$i18n.locale = 'en'
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
  },

  filters: {
    //数字格式化
    playvolume: function (arg) {
      if (arg > 1000) {
        return arg.toLocaleString()
      }
      else {
        return arg
      }
    },
  },

  methods: {
    getUserInfo(data) {
      let that = this
      this.userInfos = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfos.lang.substring(0, 2)
      let lang = this.userInfos.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }

      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('transfer.contact_content29')
      this.addressBook()

    },

    getAddressBook() {
      if (this.userInfos != null) {
        this.addressBook()
      }
    },

    addressBook() {
      let that = this
      let userInfos
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + '/api/trade/addressBook/list?token=' + that.token + '&uid=' + that.uid + '&page=' + that.page + '&size=' + that.size,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          setTimeout(() => {
            // 加载状态结束
            this.loading = false;
            // this.userInfo = this.userInfo.concat(data.data.list)
            userInfos = data.data.list
            for (let indexs = 0; indexs < data.data.list.length; indexs++) {
              for (let index = 0; index < language.length; index++) {
                if (data.data.list[indexs].cc.toUpperCase() == language[index].abbreviate) {
                  // area = language[index].enName
                  if (this.$i18n.locale == 'zh' || this.$i18n.locale == 'tc') {
                    userInfos[indexs].diqu = language[index].name
                  }
                  else if (this.$i18n.locale == 'en') {
                    userInfos[indexs].diqu = language[index].enName
                  }
                  else {
                    userInfos[indexs].diqu = language[index].selfName
                  }
                  userInfos[indexs].areaUrl = require('../../assets/transfer/flags-mini/' + data.data.list[indexs].cc + ".png")
                }
              }
            }
            this.userInfo = this.userInfo.concat(userInfos)
            this.page = this.page + 1
            //数据全部加载完成
            if (data.data.list == '') {
              this.finished = true;
            }
          }, 200);
        }
        else {
          this.loading = false;
          this.error = true;
          Toast(data.message || this.$t('transfer.error'))
        }
      })

    },
    chuandi(item) {
      Cookies.set('userId', item)//存储用户信息
      this.$router.push({ name: 'transferMoney' })
    }
  },
};
</script>

<style>
html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
}

html[lang="ar"] .jiantou {
  transform: rotate(180deg);
}

.flexs {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 1.02rem;
  height: 1.02rem;
  overflow: hidden;
}

.userInfo {
  padding-left: 0.2rem;
  text-align: left;
}

.userInfo .userid {
  color: #999999;
  font-size: 0.188rem;
  font-family: Regular;
  padding-bottom: 0.13rem !important;
  line-height: 1;
}

.userInfo .name {
  color: #010101;
  font-size: 0.27rem;
  font-family: mediums;
  line-height: 1;
  padding-bottom: 0.08rem;
}

.userInfo .diqus {
  font-family: mediums;
  padding-left: 0.06rem;
  font-size: 0.2rem;
}

.userInfo .guoqi {
  border-radius: 2px;
  height: 0.239rem;
}

.avatar .van-icon__image {
  border-radius: 50%;
  width: 1.02rem;
  height: 1.02rem;
}

.avatar .van-image__img {
  border-radius: 50%;
  width: 1.02rem;
  height: 1.02rem;
}

.jiantou {
  width: 0.37rem;
  height: 0.37rem;
}

/* .van-field__body {
        border-bottom: 1px solid #ccc;
    } */
</style>